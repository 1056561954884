import { ElementType } from 'react';

// TODO: Find a way to only load the template component we require for the current page.
import {
    BlogCategoryPage,
    getServerSideProps as getServerSidePropertiesBlogCategoryPage,
} from '../BlogCategoryPage/BlogCategoryPage';
import {
    BlogHomePage,
    getBlogHomePageServerSideProps as getServerSidePropertiesBlogHome,
} from '../BlogHomePage';
import {
    BlogPostPage,
    getBlogPostPageServerSideProps as getBlogPostPageServerSideProperties,
} from '../BlogPostPage';
import {
    GymPage,
    getGymPageServerSideProps as getServerSidePropertiesGymPage,
    mockData as mockDataGymPage,
} from '../GymPage';
import { Information } from '../Information';
import {
    RegionPage,
    getServerSidePropertiesRegionPage,
    mockDataRegionPage,
} from '../RegionPage';
import { StandardPage } from '../StandardPage';

export type GetServerSideProperties =
    | typeof getServerSidePropertiesGymPage
    | typeof getServerSidePropertiesRegionPage
    | typeof getServerSidePropertiesBlogCategoryPage
    | typeof getServerSidePropertiesBlogHome
    | typeof getBlogPostPageServerSideProperties;

export type MockData = typeof mockDataGymPage | typeof mockDataRegionPage;

/**
 * Template components and associated functions indexed by schema ID.
 * New template components should be added to this map.
 */
export const templateComponents = new Map<
    string,
    {
        component: ElementType;
        getServerSideProps?: GetServerSideProperties;
        mockData?: MockData;
    }
>([
    [
        'https://thegymgroup.com/apps/commerce/compositions/Information/Information.schema.json',
        { component: Information },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/StandardPage/StandardPage.schema.json',
        { component: StandardPage },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/GymPage/GymPage.schema.json',
        {
            component: GymPage,
            getServerSideProps: getServerSidePropertiesGymPage,
            mockData: mockDataGymPage,
        },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/BlogPostPage/BlogPostPage.schema.json',
        {
            component: BlogPostPage,
            getServerSideProps: getBlogPostPageServerSideProperties,
        },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/BlogHomePage/BlogHomePage.schema.json',
        {
            component: BlogHomePage,
            getServerSideProps: getServerSidePropertiesBlogHome,
        },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/BlogCategoryPage/BlogCategoryPage.schema.json',
        {
            component: BlogCategoryPage,
            getServerSideProps: getServerSidePropertiesBlogCategoryPage,
        },
    ],
    [
        'https://thegymgroup.com/apps/commerce/compositions/RegionPage/RegionPage.schema.json',
        {
            component: RegionPage,
            getServerSideProps: getServerSidePropertiesRegionPage,
            mockData: mockDataRegionPage,
        },
    ],
]);
