import { ContentClient } from 'dc-delivery-sdk-js';
import {
    ContentItemResponse,
    ISortBy,
} from 'dc-delivery-sdk-js/build/main/lib/content/model/FilterBy';

import {
    CmsClientConstructorConfig,
    CmsContentSchema,
    FilterableContentType,
    FilterOptions,
} from './CmsClient.types';

// Is there a more scalable way to do this? Should these live somewhere centralised?
/**
 * Allows consumers to filter by content types without having to know about the schema IDs
 */
const contentTypeMap = new Map<FilterableContentType, string>([
    [
        'gyms',
        'https://thegymgroup.com/libs/ui/src/lib/components/Gym/Gym.schema.json',
    ],
    [
        'gymPages',
        'https://thegymgroup.com/apps/commerce/compositions/GymPage/GymPage.schema.json',
    ],
    [
        'regionPages',
        'https://thegymgroup.com/apps/commerce/compositions/RegionPage/RegionPage.schema.json',
    ],
    [
        'standardPages',
        'https://thegymgroup.com/apps/commerce/compositions/StandardPage/StandardPage.schema.json',
    ],
    [
        'staticPages',
        'https://thegymgroup.com/apps/commerce/compositions/StaticPage/StaticPage.schema.json',
    ],
    [
        'pages',
        'https://thegymgroup.com/libs/ui/src/lib/components/Page/Page.schema.json',
    ],
    [
        'blogHomePages',
        'https://thegymgroup.com/apps/commerce/compositions/BlogHomePage/BlogHomePage.schema.json',
    ],
    [
        'blogPages',
        'https://thegymgroup.com/apps/commerce/compositions/BlogPostPage/BlogPostPage.schema.json',
    ],
    [
        'blogCategoryPages',
        'https://thegymgroup.com/apps/commerce/compositions/BlogCategoryPage/BlogCategoryPage.schema.json',
    ],
    [
        'redirects',
        'https://thegymgroup.com/libs/ui/src/lib/components/Redirects/Redirects.schema.json',
    ],
]);

export class CmsClient {
    private client: ContentClient;

    constructor({
        hubName,
        freshApiKey,
        isFreshOn,
    }: CmsClientConstructorConfig) {
        this.client = new ContentClient({
            hubName,
            apiKey: isFreshOn ? freshApiKey : undefined,
        });
    }

    public async getContentItem(
        deliveryKey: string,
    ): Promise<CmsContentSchema> {
        try {
            const item = await this.client.getContentItemByKey(deliveryKey);

            return item.toJSON() as CmsContentSchema;
        } catch {
            // TODO: Log this missing content item in Application Insights (once it's configured).
            return Promise.resolve({
                _meta: {
                    name: 'Error',
                    schema: 'Error',
                    deliveryId: 'error',
                },
            } as CmsContentSchema);
        }
    }

    public async getContentItemsByType<
        T extends FilterableContentType,
        R = CmsContentSchema[],
    >(
        contentType: T,
        {
            filterOptions,
            sortBy,
            nextCursor,
            previousResponse,
        }: {
            filterOptions?: Partial<FilterOptions[T]>;
            sortBy?: ISortBy;
            nextCursor?: string;
            previousResponse?: CmsContentSchema[];
        } = {},
    ): Promise<CmsContentSchema[] | R> {
        const type = contentTypeMap.get(contentType);
        const filterBy = [
            {
                path: '/_meta/schema',
                value: type,
            },
        ];

        if (filterOptions) {
            Object.entries(filterOptions).forEach(([key, value]) => {
                filterBy.push({
                    path: `/${key}`,
                    value,
                });
            });
        }

        try {
            const items = await this.client.filterContentItems({
                filterBy,
                sortBy,
                page: {
                    cursor: nextCursor,
                },
                parameters: {
                    depth: 'all',
                    format: 'inlined',
                },
            });

            const sortedItems: CmsContentSchema[] = [
                ...items.responses.map(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    (item: ContentItemResponse) => item.content,
                ),
                ...(previousResponse || []),
            ];

            if (items.page?.nextCursor) {
                return this.getContentItemsByType(contentType, {
                    filterOptions,
                    sortBy,
                    nextCursor: items.page.nextCursor,
                    previousResponse: sortedItems,
                });
            }
            return sortedItems;
        } catch {
            return Promise.resolve([
                {
                    _meta: {
                        name: 'Error',
                        schema: 'Error',
                        deliveryId: 'error',
                    },
                },
            ] as CmsContentSchema[]);
        }
    }
}
