import { isAxiosError } from '../axios';
import { ServerLoggerLegacy } from './serverLoggerLegacy';

export const generateErrorMessage = (
    error: unknown,
    logger: ServerLoggerLegacy,
    message: { page: string; endpointName: string },
    customRedirect?: (error: unknown) => {
        redirect: {
            destination: string;
            permanent: boolean;
        };
    },
) => {
    if (customRedirect) {
        return customRedirect(error);
    }

    const { page, endpointName } = message;

    logger.debug(
        `getServerSideProps in ${page} page - redirecting to /500 - there was a problem with allowing access to ${endpointName}`,
    );
    if (isAxiosError(error)) {
        logger.error(
            `getServerSideProps in ${page} page - there was an error when calling APIM - error: ${JSON.stringify(
                error.response?.data,
            )} - request url: ${
                error.response?.config.url
            } - response status: ${error.response?.status} `,
        );
    } else {
        logger.error(
            `${page} - error unrelated to APIM - error: ${
                (error as Error).message
            }`,
        );
    }

    return {
        redirect: {
            destination: '/500/',
            permanent: false,
        },
    };
};
