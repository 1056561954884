import { useMediaQuery } from '@material-ui/core';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { BlogPostProperties } from './BlogPostPage.types';
import { BreadcrumbsElement } from '@tgg/common-types';
import { CmsClient } from '@tgg/micro-services/cms-client';
import { Breadcrumbs, CmsContent, theme } from '@tgg/ui';
import { getBlogHomePage, getCategoryPagesMap } from '@tgg/util';
import { LocalConfig } from 'apps/commerce/next.config.types';

const {
    publicRuntimeConfig: { AMPLIENCE_HUB_ID },
    serverRuntimeConfig: { AMPLIENCE_FRESH_API_KEY, AMPLIENCE_FRESH_API_ON },
}: LocalConfig = getConfig();

export function BlogPostPage({
    titleBox,
    content,
    cmsPage,
    breadcrumbElements,
    heroWithOverlay,
}: BlogPostProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const contextualProperties = {
        Generic: {
            page: cmsPage,
        },
        ButtonStandaloneCms: {
            ctaPosition: 'homepage body',
        },
    };

    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {heroWithOverlay && <CmsContent schema={heroWithOverlay} />}
            {isDesktop && breadcrumbElements && (
                <Breadcrumbs elements={breadcrumbElements} />
            )}
            {content.map(contentItem => (
                <CmsContent
                    key={uuidv4()}
                    schema={contentItem}
                    contextualProps={contextualProperties}
                />
            ))}
        </>
    );
}

export const getServerSideProps = async (
    _context: GetServerSidePropsContext,
): Promise<{ props: BlogPostProperties }> => {
    const { params } = _context;

    const { details, content, ...restParameters } = params as unknown as Omit<
        BlogPostProperties,
        'breadcrumbElements'
    >;
    const cmsClient = new CmsClient({
        hubName: AMPLIENCE_HUB_ID,
        freshApiKey: AMPLIENCE_FRESH_API_KEY,
        isFreshOn: AMPLIENCE_FRESH_API_ON,
    });

    const getCategoryPagesMapPromise = getCategoryPagesMap(cmsClient);
    const getBlogHomePagePromise = getBlogHomePage(cmsClient);

    const [categoryPages, blogHomePage] = await Promise.all([
        getCategoryPagesMapPromise,
        getBlogHomePagePromise,
    ]);

    const categoryDeliveryKey =
        categoryPages[details.mainSubcategory.parentCategory.name];

    const breadcrumbElements: BreadcrumbsElement[] = [
        {
            label: (blogHomePage.titleBox?.text || 'blog') as string,
            // eslint-disable-next-line no-underscore-dangle
            url: blogHomePage._meta.deliveryKey,
        },
        {
            label: details.mainSubcategory.parentCategory.name,
            url: categoryDeliveryKey,
        },
        {
            label: details.title,
        },
    ];

    const heroWithOverlay = content?.find(
        item =>
            // eslint-disable-next-line no-underscore-dangle
            item._meta.schema ===
            'https://thegymgroup.com/libs/ui/src/lib/components/HeroWithOverlay/HeroWithOverlay.schema.json',
    );

    if (heroWithOverlay) {
        content.splice(content.indexOf(heroWithOverlay), 1);
    }

    return {
        props: {
            ...restParameters,
            details,
            content,
            breadcrumbElements,
            heroWithOverlay: heroWithOverlay ?? null,
        },
    };
};
