import {
    BlogCategoryPageContent,
    BlogHomePageContent,
    BlogPostProperties,
} from '@tgg/common-types';
import { CmsClient } from '@tgg/micro-services/cms-client';

export const getAllPosts = async (cmsClient: CmsClient) => {
    return cmsClient.getContentItemsByType<'blogPages', BlogPostProperties[]>(
        'blogPages',
        {
            filterOptions: {
                'details/isAvailableOnPostList': true,
            },
            sortBy: {
                key: 'default',
                order: 'DESC',
            },
        },
    ) as unknown as BlogPostProperties[];
};

export const getCategoryPagesMap = async (cmsClient: CmsClient) => {
    const blogCategoryPages = (await cmsClient.getContentItemsByType<
        'blogCategoryPages',
        BlogCategoryPageContent[]
    >('blogCategoryPages')) as unknown as BlogCategoryPageContent[];

    return blogCategoryPages.reduce(
        (accumulator, { category: currentCategory, _meta: meta }) => {
            return { ...accumulator, [currentCategory.name]: meta.deliveryKey };
        },
        {} as Record<string, string>,
    );
};

export const getBlogHomePage = async (cmsClient: CmsClient) => {
    const blobHomePageArray = (await cmsClient.getContentItemsByType<
        'blogHomePages',
        BlogHomePageContent[]
    >('blogHomePages')) as unknown as BlogHomePageContent[];

    return blobHomePageArray[0];
};
